import { graphql } from "gatsby";
import React, { useContext, useEffect } from "react";
import { LayoutContainer } from "../containers/layout";
import PageHeadMeta from "../components/global/page-head-meta";
import { SectionWrapper } from "../components/sections";
import PortableText from "../components/portableText";
import { SiteContext } from "../components/global/site-context";
import { Button } from "../components/button";
import { ImageBuilder } from "../components/global/image-builder";

const TEASER_IMAGE_HEIGHT = 170;
const REDIRECT_DELAY = 2000;

export const query = graphql`
  query AssetRedirectTemplateQuery($id: String!) {
    doc: sanityAsset(id: { eq: $id }) {
      _type
      _id
      status
      title
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      mainImage {
        ...SanityImage
      }
      slug {
        current
      }
      _rawBody(resolveReferences: { maxDepth: 12 })
      file {
        asset {
          url
        }
      }
      externalUrl
    }
  }
`;

const AssetRedirectTemplate = ({ data }) => (
  <LayoutContainer doc={data.doc} hideNav={true}>
    <PageHeadMeta doc={data.doc} />
    {data.doc && <AssetRedirectPage {...data.doc} />}
  </LayoutContainer>
);

export default AssetRedirectTemplate;

const AssetRedirectPage = ({
  title,
  _rawExcerpt,
  mainImage,
  file,
  externalUrl,
}) => {
  const site = useContext(SiteContext);
  const assetUrl = file?.asset?.url || externalUrl;

  const urlParams =
    typeof window === "undefined"
      ? new URLSearchParams()
      : new URLSearchParams(window.location.search);

  useEffect(() => {
    if (urlParams.has("click")) return;

    if (site.isBrowser && assetUrl) {
      setTimeout(() => {
        window.location.replace(assetUrl);
      }, REDIRECT_DELAY);
    }
  }, []);

  return (
    <SectionWrapper>
      <div className="mt-12 mb-24 max-w-screen-sm">
        {!urlParams.has("click") && (
          <h1 className="text-2xl md:text-5xl font-bold tracking-tight mb-4">
            Redirecting to...
          </h1>
        )}
        <h2 className="text-3xl font-semibold mb-4">{title}</h2>
        <div>
          {mainImage && (
            <ImageBuilder
              image={mainImage}
              height={TEASER_IMAGE_HEIGHT}
              alt={title}
              className="mb-4"
            />
          )}
        </div>
        <div className="text-left mb-4">
          <PortableText blocks={_rawExcerpt} />
        </div>
        {assetUrl && (
          <>
            <Button
              text="Download for free"
              bgColor="bg-black"
              href={assetUrl}
              onClick={() => {
                site.metrics.logClick("asset-redirect");
              }}
            />
          </>
        )}
      </div>
    </SectionWrapper>
  );
};
